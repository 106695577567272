import { DropzoneArea, DropzoneAreaProps } from "material-ui-dropzone";
import { makeStyles, createStyles } from "@material-ui/core";
import { Spacing, FontSize } from "../theme/Theme";
import React from "react";

const useStyles = makeStyles((theme) => ({
    fileDropzone: {
      width: '100px', // Adjust width as needed
      //height: '20px', // Ensure height accommodates icon and text
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    smDropzone: {
      width: '100% !important',
      minHeight: 20, // Set a minimum height to fit both icon and text
      padding: '4px !important', // Slight padding to avoid excess whitespace
      '& .MuiDropzoneArea-icon': {
        fontSize: '20px', // Adjust icon size if needed
        height: 20,
      },
      '& .MuiDropzoneArea-textContainer': {
        display: 'block', // Ensure text container is visible
        fontSize: '10px', // Adjust font size for compact view
        marginTop: '2px', // Add some space between icon and text
      },
      '& .MuiDropzoneArea-text': {
        marginTop :'10px !important',
        marginBottom:"0px !important"
      },
    },
    text: {
        fontSize: '10px', // Adjust font size if dropzone text is used
      },
    disabled: {
    pointerEvents: "none", // prevents clicks
    opacity: 0.5, // visually indicates disabled
    }
  }));

interface RBDropFileProps extends DropzoneAreaProps {
    fileValid?: boolean;
    disableUpload?: boolean;
}
export const RBDocumentDropFile: React.FC<RBDropFileProps> = ({ ...props }) => {
  const classes = useStyles();
  return (
    <div className={`${classes.fileDropzone} ${props.disableUpload ? classes.disabled : ""}`}>
      <DropzoneArea
        filesLimit={1}
        previewGridProps={{ container: { spacing: 5 }, item: { xs: 12 } }}
        inputProps={{ style: { backgroundColor: "green" } }}
        classes={{
          root: classes.smDropzone,
          text: classes.text,
        }}
        {...props}
        onDelete={(deleteFileObj) => {
          console.log("onDelete", deleteFileObj);
        }}
        showFileNames={props.fileValid}
      />
    </div>
  );
};
