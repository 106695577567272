import React, { useState, useRef, useEffect,useContext } from "react";
import { ObjectLiteral } from "../../../interfacesProps/ObjectLiteralProps";
import { ProfileCardSection } from "./ProfileCardSection";
import { ErrorMessageProps, Form, Formik } from "formik";
import { RBButton, RBButtonType } from "../../../components/RBButton";
import { Colors,Spacing } from "../../../theme/Theme";
import { ColumnStyle } from "../../../theme/ComponentTheme";
import {
  RenderTextWithTitle,
  renderTextWithTitleForSkills,
  renderTextWithTitleAndChildComponent,
} from "../../../components/TextWithHeader";
import { Schema } from "../YupSchema";
import {
  RBTextFieldForForm,
  RBTextFieldForFormFieldType,
  RBSelectForForm,
} from "../../../components/RBTextField";
import { PharmacyRxCounts, NotAvailableString, IndustryModeEnum, InvoiceRecipientContactMethod } from "../../../util/Common";
import { SkillResponseDto } from "../../../dtos/Skill.dto";
import { PharmacyService } from "../../../api/PharmacyService";
import { SkillType } from "../../../util/Enumeration";
import { MenuItem, ListItemText, Typography ,Checkbox, FormControlLabel} from "@material-ui/core";
import { RBPlaceSearchForForm2 } from "../../../components/RBPlaceSearch";
import { AuthService } from "../../../api/AuthService";
import NotificationService, {
  NotificationServiceTopic,
} from "../../../api/NotificationService";
import { RBMap } from "../../../components/RBMap";
import formatService from "../../../util/FormattingService";
import { LabelText } from "../../../util/TextConstant";
import { RBContext } from "../../../components/RBContext";
import { RBEmptyBlock } from "../../../components/RBEmptyBlock";
import * as Yup from "yup";
import { Regexes } from "../../../util/Default";

interface DentalPracticeDetailSectionProps {
  accountInfo: ObjectLiteral;
}

interface Payload {
  pharmacyId: any;
  phone: any;
  fax: any;
  storeNumber: any;
  rxCount: any;
  name: any;
  companyType: any;
  dentalOfficeCompanyInfo: {
    benefits: any;
    overview: any;
    services: any;
    website: any;
  };
  skills: {
    required: boolean;
    skillId: any;
  }[];
  address: {
    apartmentSuiteNumber: any;
    streetAddress: any;
    city: any;
    province: any;
    postalCode: any;
  };
  contactFirstName: any;
  contactLastName: any;
  contactPhone: any;
  invoiceRecipient: any;
  billingEmail?: string; // Add this line to make it part of the Payload interface
  notifyAdminOnBillingEmailChange?: boolean;
}

export const DentalPracticeDetailSection: React.FC<DentalPracticeDetailSectionProps> = ({
  ...props
}) => {
  const { industryMode,primaryColor,secondaryColor } = useContext(RBContext);
  const { accountInfo } = props;
  const defaultPharmacyId =
    accountInfo.owner.defaultPharmacyId ?? accountInfo.pharmacies[0].id;
  const pharmacy = accountInfo.pharmacies.filter(
    (pharmacy: { id: any }) => pharmacy.id === defaultPharmacyId
  )[0];
  const [isEdit, setIsEdit] = useState(false);
  const formRef = useRef<any>();
  const [submitErrorMessage, setSubmitErrorMessage] = useState<
    ErrorMessageProps
  >();
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(false);
  const addressRef = useRef<any>();

  const [softwares, setSoftwares] = useState<SkillResponseDto[]>([]);
  const [DentalOwnerPosition, setDentalOwnerPosition] = useState<any>([]);
  const [isBillingEnabled, setIsBillingEnabled] = useState(pharmacy.invoiceRecipient === InvoiceRecipientContactMethod[0].id);
  const [invoiceRecipientArr, setInvoiceRecipientArr] = useState<any>(pharmacy.invoiceRecipient === InvoiceRecipientContactMethod[0].id ?
    [InvoiceRecipientContactMethod[0]] : InvoiceRecipientContactMethod.filter(contact => contact.id !== InvoiceRecipientContactMethod[0].id),);
  const [isBillingEnabledSaved, setIsBillingEnabledSaved] = useState(pharmacy.invoiceRecipient === InvoiceRecipientContactMethod[0].id);
 

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);
  const setLoadingValue = (value: boolean) => {
    isMountedRef.current && setIsLoading(value);
  };

  const fetchSkills = async () => {
    try {
      setLoadingValue(true);
      const dentalCompanyTypes = await AuthService.getLocationTypesBasedonIndustry(IndustryModeEnum.Dentistry);
      const DentalOfficeCompanyType = dentalCompanyTypes.map((item:any) => item.locationType);
      setDentalOwnerPosition(DentalOfficeCompanyType)
      
      const fetchSkillsData = await PharmacyService.fetchSkills('Dental Practice');
      setSoftwares(
        (fetchSkillsData.data as SkillResponseDto[])
          .filter(
            (skill: SkillResponseDto) => skill.type === SkillType.dentalSoftware
          )
          .sort(function (a, b) {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          })
      );
    } catch (err) {
      const errorMessage = err.response ? err.response.data : err.message;
      setSubmitErrorMessage(errorMessage);
    } finally {
      setLoadingValue(false);
    }
  };
  const renderAddress = () => {
    const [lng, lat] = pharmacy.address.coordinate.coordinates;
    const addressFormat = pharmacy.address.apartmentSuiteNumber
      ? pharmacy.address.apartmentSuiteNumber +
        " - " +
        pharmacy.address.streetAddress +
        " \n " +
        pharmacy.address.city +
        " " +
        pharmacy.address.province +
        " \n " +
        pharmacy.address.postalCode
      : pharmacy.address.streetAddress +
        "\n" +
        pharmacy.address.city +
        " " +
        pharmacy.address.province +
        "\n" +
        pharmacy.address.postalCode;
    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Address", addressFormat)}
          {/* <Typography
            variant={"body1"}
            style={{
              display: "flex",
              flex: 1,
              overflowWrap: "anywhere",
              whiteSpace: "pre-wrap",
            }}
          >
            {addressFormat}
          </Typography> */}

          <RBMap
            style={{
              display: "flex",
              flex: 1,
              height: 200,
              marginTop: Spacing.medium,
            }}
            lat={lat}
            lng={lng}
          />
        </div>
      </>
    );
  };
  const editDetail = () => {
    const DentalPracticeDetailSection = Yup.object().shape({
      pharmacyName: Yup.string().required("Required"),
      companyType: Yup.string()
        .required("Required"),
      pharmacySoftware: Yup.string().required("Required"),
      InvoiceRecipientContact: Yup.string().required("Required"),
      billingEmail: isBillingEnabled === false ?
          Yup.string()
          .required("Required")
          .matches(Regexes.emailPattern, "must be a valid email address")
          .test("is-not-owner-email", "Billing email is same as owner email", function (value) {
            console.log("isBillingEnabled in validation:", isBillingEnabled);
            return value?.toLocaleLowerCase() !== localStorage.getItem("ownerEmail")?.toLocaleLowerCase();
          })
        : Yup.string().notRequired()
    
    });
    return (
      <Formik
        innerRef={formRef as any}
        initialValues={{
          pharmacyName: pharmacy.name,
          companyType: pharmacy.companyType,
          website: pharmacy.dentalOfficeCompanyInfo?.website,
          overview: pharmacy.dentalOfficeCompanyInfo?.overview,
          services: pharmacy.dentalOfficeCompanyInfo?.services,
          benefits: pharmacy.dentalOfficeCompanyInfo?.benefits,
          pharmacySoftware:
            pharmacy.skills.length > 0 ? pharmacy.skills[0].id : undefined,

          street: pharmacy.address.streetAddress,
          city: pharmacy.address.city,
          postalCode: pharmacy.address.postalCode,
          province: pharmacy.address.province,
          apartmentSuiteNumber: pharmacy.address.apartmentSuiteNumber,
          InvoiceRecipientContact: pharmacy.invoiceRecipient,
          billingEmail:pharmacy.billingEmail ?pharmacy.billingEmail : "",
          isBillingEnabled:pharmacy.invoiceRecipient === InvoiceRecipientContactMethod[0].id ? true : false
        }}
        validationSchema={DentalPracticeDetailSection}
        validateOnBlur={true}
        validateOnMount={true}
        validateOnChange={true}
        isInitialValid={DentalPracticeDetailSection.isValidSync({
          pharmacyName: pharmacy.name,
          companyType: pharmacy.companyType,
          website: pharmacy.dentalOfficeCompanyInfo?.website,
          overview: pharmacy.dentalOfficeCompanyInfo?.overview,
          services: pharmacy.dentalOfficeCompanyInfo?.services,
          benefits: pharmacy.dentalOfficeCompanyInfo?.benefits,
          pharmacySoftware:
            pharmacy.skills.length > 0 ? pharmacy.skills[0].id : undefined,

          street: pharmacy.address.streetAddress,
          city: pharmacy.address.city,
          postalCode: pharmacy.address.postalCode,
          province: pharmacy.address.province,
          apartmentSuiteNumber: pharmacy.address.apartmentSuiteNumber,
          InvoiceRecipientContact: pharmacy.invoiceRecipient,
          billingEmail:pharmacy.billingEmail
        })}
        onSubmit={() => {}}
      >
        {({
          values,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          validateField
        }) => (
          <Form onSubmit={handleSubmit}>
            {submitErrorMessage && (
              <div style={{ color: "red" }}>
                {JSON.stringify(submitErrorMessage, null, 2)}
              </div>
            )}
            {renderTextWithTitleAndChildComponent(
              "Practice Name",
              <RBTextFieldForForm
                value={values.pharmacyName}
                name="pharmacyName"
                FieldType={RBTextFieldForFormFieldType.default}
                size={"small"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}
            {renderTextWithTitleAndChildComponent(
              "Practice Type",
              <RBSelectForForm
                name="companyType"
                value={values.companyType}
                mappingValues={DentalOwnerPosition}
                style={{
                marginBottom: Spacing.large,
                }}
               />
            )}

            {renderTextWithTitleAndChildComponent(
              "Website",
              <RBTextFieldForForm
                value={values.website}
                name="website"
                size={"small"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}

            {renderTextWithTitleAndChildComponent(
              "Overview",
              <RBTextFieldForForm
                value={values.overview}
                name="overview"
                size={"small"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}

            {renderTextWithTitleAndChildComponent(
              "Services",
              <RBTextFieldForForm
                value={values.services}
                name="services"
                size={"small"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}
            {renderTextWithTitleAndChildComponent(
              "Benefits",
              <RBTextFieldForForm
                value={values.benefits}
                name="benefits"
                size={"small"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}
            {renderTextWithTitleAndChildComponent(
              "Software",
              <RBSelectForForm
                value={values.pharmacySoftware}
                name="pharmacySoftware"
                renderValue={(value) => {
                  return (
                    <div>
                      {softwares.filter((skill) => skill.id === value).length >
                      0
                        ? softwares.filter((skill) => skill.id === value)[0]
                            .name
                        : NotAvailableString}
                    </div>
                  );
                }}
                margin={"dense"}
                style={{ marginBottom: Spacing.large }}
                onChange={(e: any) => {
                  console.log(e);
                  handleChange(e);
                }}
              >
                {softwares.map((skill) => (
                  <MenuItem key={skill.id} value={skill.id}>
                    <ListItemText primary={skill.name} />
                  </MenuItem>
                ))}
              </RBSelectForForm>
            )}

             <div style={{ ...ColumnStyle, alignItems: "center" }}>
            <Typography style={{ display: "flex", flex: 1 }}>
              Billing email is same as owner email?
            </Typography>
            <RBEmptyBlock />
            <Checkbox
              checked={isBillingEnabled}
              style={{
                color: isBillingEnabled
                  ? localStorage.getItem("secondaryColor") ?? Colors.themeLightBlue
                  : Colors.darkGrey,
              }}
              name="isBillingEnabled"
              onBlur={handleBlur}
              onChange={(_, checked) => {
                setIsBillingEnabled(!isBillingEnabled);
                setFieldValue("isBillingEnabled", checked);
                const updatedBillingEnabled = checked;

                if (updatedBillingEnabled) {
                  setInvoiceRecipientArr([InvoiceRecipientContactMethod[0]]);
                  setFieldValue("InvoiceRecipientContact", InvoiceRecipientContactMethod[0].id);
                } else {
                  setInvoiceRecipientArr(
                    InvoiceRecipientContactMethod.filter(contact => contact.id !== InvoiceRecipientContactMethod[0].id)
                  );
                  setFieldValue("InvoiceRecipientContact", InvoiceRecipientContactMethod[2].id);
                  setFieldValue("billingEmail", pharmacy.billingEmail !== null ? pharmacy.billingEmail : '' )

                  if (!isBillingEnabledSaved) {
                    setFieldValue("InvoiceRecipientContact", pharmacy.invoiceRecipient);
                  }
                }
              }}
            />

          </div>
          { (!isBillingEnabled) &&
            renderTextWithTitleAndChildComponent('Billing Email',
               <RBTextFieldForForm
               value={values.billingEmail}
               name="billingEmail"
               size={"small"}
               style={{ marginBottom: Spacing.large }}
               onChange={async (e:any) => {
                const { value } = e.target;
                handleChange(e)
                await setFieldValue('billingEmail', value);
                // Force validate the InvoiceRecipientContact field immediately
                validateField('billingEmail');
                
              }
              }
               onBlur={handleBlur}
             />
            
            )}

            {renderTextWithTitleAndChildComponent('Invoice Recipient',
               <RBSelectForForm
               name="InvoiceRecipientContact"
               value={values.InvoiceRecipientContact}
               onChange={async (e:any) => {
                 const { value } = e.target;
                 handleChange(e)
                 await setFieldValue('InvoiceRecipientContact', value);
                 // Force validate the InvoiceRecipientContact field immediately
                 validateField('InvoiceRecipientContact');
                 
               }
               }
               onBlur={handleBlur}
               placeholder={"Please select ..."}
               style={{
                 marginBottom: Spacing.large,
               }}
             >
               {invoiceRecipientArr.map((recipient:any) => (
                 <MenuItem key={recipient.id} value={recipient.id}>
                   <div>{recipient.value}</div>
                 </MenuItem>
               ))}
             </RBSelectForForm>
            
            )}


            <div
              style={{
                height: 1,

                marginTop: Spacing.large,
                marginBottom: Spacing.large,
              }}
            />
            <RBPlaceSearchForForm2
              innerRef={addressRef}
              initialValues={{
                street: pharmacy.address.streetAddress,
                city: pharmacy.address.city,
                postalCode: pharmacy.address.postalCode,
                province: pharmacy.address.province,
                apartmentSuiteNumber: pharmacy.address.apartmentSuiteNumber,
              }}
              isProvinceEditable={false}
            />
            <div
              style={{
                height: 1,

                //marginTop: Spacing.large,
                marginBottom: Spacing.large,
              }}
            />
            {/* {renderTextWithTitleAndChildComponent(
              "Designated Manager First Name",
              <RBTextFieldForForm
                value={values.pharmacyDesignatedManagerFirstName}
                name="pharmacyDesignatedManagerFirstName"
                FieldType={RBTextFieldForFormFieldType.default}
                size={"small"}
                style={{
                  marginBottom: Spacing.large,
                }}
                onChange={handleChange}
              />
            )}
            {renderTextWithTitleAndChildComponent(
              "Designated Manager Last Name",
              <RBTextFieldForForm
                value={values.pharmacyDesignatedManagerLastName}
                name="pharmacyDesignatedManagerLastName"
                FieldType={RBTextFieldForFormFieldType.default}
                size={"small"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )}
            {renderTextWithTitleAndChildComponent(
              "Designated Manager Phone",
              <RBTextFieldForForm
                value={values.pharmacyDesignatedManagerPhone}
                name="pharmacyDesignatedManagerPhone"
                FieldType={RBTextFieldForFormFieldType.default}
                size={"small"}
                style={{ marginBottom: Spacing.large }}
                onChange={handleChange}
              />
            )} */}
          </Form>
        )}
      </Formik>
    );
  };
  const renderDetail = () => {
    console.log(
      "PharmacyDetailSection render detail pharmacy",
      defaultPharmacyId,
      accountInfo,
      pharmacy
    );
    const software =
      pharmacy.skills.length > 0
        ? pharmacy.skills.map((skill: any) => skill.name)
        : [];

    const selectedInvoiceRecipient = InvoiceRecipientContactMethod.find(method => method.id === pharmacy.invoiceRecipient);
    const displayInvoiceRecipient = selectedInvoiceRecipient ? selectedInvoiceRecipient.value : 'N/A';
    return (
      <>
        <div style={ColumnStyle}>
          {RenderTextWithTitle("Practice Name", pharmacy.name)}
          {RenderTextWithTitle("Practice Type", pharmacy.companyType)}
        </div>
        {renderAddress()}
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Company Website",
            pharmacy.dentalOfficeCompanyInfo?.website
          )}
          {RenderTextWithTitle(
            "Company Overview",
            pharmacy.dentalOfficeCompanyInfo?.overview
          )}
        </div>
        <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Company Services",
            pharmacy.dentalOfficeCompanyInfo?.services
          )}
          {RenderTextWithTitle(
            "Company Benefits",
            pharmacy.dentalOfficeCompanyInfo?.benefits
          )}
        </div>
        <div style={ColumnStyle}>
          {renderTextWithTitleForSkills("Software", software)} 
          {RenderTextWithTitle(
            "Invoice Recipient",
            displayInvoiceRecipient
          )}
        </div>
        {selectedInvoiceRecipient?.id !== InvoiceRecipientContactMethod[0].id && 
         <div style={ColumnStyle}>
          {RenderTextWithTitle(
            "Billing Email",
            pharmacy.billingEmail
          )}
        </div>}
      </>
    );
  };
  const onSaveChange = async () => {
    try {
      if (formRef.current) {
        if (addressRef.current) {
          addressRef.current.handleSubmit();
        }

        formRef.current.handleSubmit();

        if (!formRef.current.isValid) {
          console.log("pharmacy detail is not Valid ", formRef.current);
          return;
        } else if (!addressRef.current.isValid) {
          console.log(
            "pharmacy detail address is not Valid ",
            addressRef.current
          );
          return;
        } else {
          setLoadingValue(true);
          console.log("pharmacy detail onSaveChange ", formRef.current.values);
          let payload:Payload = {
            pharmacyId: pharmacy.id,
            phone: formRef.current.values.pharmacyPhone,
            fax: formRef.current.values.pharmacyFax,
            storeNumber: formRef.current.values.pharmacyStoreNumber,
            rxCount: formRef.current.values.pharmacyRxAccount,
            name: formRef.current.values.pharmacyName,
            companyType:formRef.current.values.companyType,
            dentalOfficeCompanyInfo: {
              benefits: formRef.current.values.benefits,
              overview: formRef.current.values.overview,
              services:formRef.current.values.services,
              website:formRef.current.values.website,
            },
            skills: [
              {
                required: true,
                skillId: formRef.current.values.pharmacySoftware,
              },
            ],
            address: {
              apartmentSuiteNumber:
                addressRef.current.values.apartmentSuiteNumber,
              streetAddress: addressRef.current.values.street,
              city: addressRef.current.values.city,
              province: addressRef.current.values.province,
              postalCode: addressRef.current.values.postalCode,
            },
            contactFirstName:
              formRef.current.values.pharmacyDesignatedManagerFirstName,
            contactLastName:
              formRef.current.values.pharmacyDesignatedManagerLastName,
            contactPhone: formRef.current.values.pharmacyDesignatedManagerPhone,
            invoiceRecipient: formRef.current.values.InvoiceRecipientContact,
          };
          if(formRef.current.values.billingEmail){
            payload = { ...payload,  billingEmail: formRef.current.values.billingEmail }
          }
          debugger
          let updatedBillingEmail = formRef.current.values.billingEmail == "" ? null : formRef.current.values.billingEmail;
          if(formRef.current.values.InvoiceRecipientContact !== pharmacy.invoiceRecipient || updatedBillingEmail !== pharmacy.billingEmail){
            payload = { ...payload, notifyAdminOnBillingEmailChange: true}
          }
          else{
            payload = { ...payload, notifyAdminOnBillingEmailChange: false}
          }
          console.log("pharmacy detail updated pharmacy", payload);
          const result = await AuthService.updatePharmacy(payload);
          NotificationService.publish(
            NotificationServiceTopic.AccountChanged,
            "account changed "
          );
          setIsEdit(false);
        }
      }
    } catch (err) {
      console.log("onSaveChange err ", err);

      const errorMessage = err.response ? err.response.data : err.message;
      setSubmitErrorMessage(errorMessage);
    } finally {
      setLoadingValue(false);
    }
  };
  const onCancelChange = () => {
    setIsEdit(false);
  };
  const renderAction = () => {
    return (
      <>
        {isEdit ? (
          <>
            <RBButton
              text="Cancel"
              buttonType={RBButtonType.cancel}
              style={{ marginRight: Spacing.medium }}
              onClick={onCancelChange}
            />
            <RBButton
              text="Save"
              onClick={onSaveChange}
              isLoading={isLoading}
              buttonBackgroundColor={secondaryColor}
            />
          </>
        ) : (
          <RBButton
            text="Edit"
            isLoading={isLoading}
            onClick={async () => {
              await fetchSkills();
              setIsEdit(true);
            }}
            buttonBackgroundColor={secondaryColor}
          />
        )}
      </>
    );
  };
  return (
    <ProfileCardSection
      sectionHeader="Dental Practice Information"
      detail={isEdit ? editDetail() : renderDetail()}
      action={renderAction()}
    />
  );
};
